<template>
  <div className="header">
    <div className="clearfix w1200">
      <div className="logo" id="logo" logo-type="small"></div>
      <a className="headerdown" href="https://ai.shuohangai.com/#/login" name="down">
        <!--				<i></i>-->
        <span>登录</span>
      </a>
      <div className="fr">
        <ul className="nav-list">
          <li>
            <a href="https://ai.shuohangai.com/#/chat">AI聊天</a>
          </li>
          <li>
            <a href="https://ai.shuohangai.com/#/chat">AI绘画</a>
          </li>
          <li>
            <a href="https://ai.shuohangai.com/#/chat">AI应用</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- index -->
  <div className="index_top">
    <div className="w1200 index_box">
      <div className="idnex_left">
        <div className="logoAndTitle">
          <i className="logo"></i>
          <h1>烁航AI</h1>
          <!-- <i class="newicon"></i> -->
        </div>
        <h2>反应迅速、多场景使用的AI撰写神器</h2>
        <h3>一键轻松写作，生成各种高质量文章和文案</h3>

        <div className="indexBtnBox">
          <a href="https://ai.shuohangai.com/#/login" className="dwonBtn">
            <!--						<i class="icon"></i>-->
            <span>登录</span>
            <i className="an"></i>
          </a>
        </div>
      </div>
      <img className="indeximg" :src="require('/src/assets/images/indeximg.jpg')">
    </div>
    <div className="indexlistshowbox">
      <div className="indexshowitem">
        <a href="https://ai.shuohangai.com/#/chat">
          <img :src="require('/src/assets/images/list3.png')">
          <p>AI聊天</p>
        </a>
      </div>
      <div className="indexshowitem">
        <a href="https://ai.shuohangai.com/#/chat">
          <img :src="require('/src/assets/images/draw.png')"
               style="background:linear-gradient(to bottom right, #AEE0FF, #1890ff) ;padding: 5px;border-radius: 4px;width: 40px;height: 40px;">
          <p>AI绘图</p>
        </a>
      </div>
      <div className="indexshowitem">
        <a href="https://ai.shuohangai.com/#/chat">
          <img :src="require('/src/assets/images/list1.png')">
          <p>AI应用</p>
        </a>
      </div>
    </div>
  </div>
  <!-- 智能AI工作管家 -->
  <div className="AIworkHousekeep">
    <h2 className="h2t">烁航AI工作助手</h2>
    <div className="housekeepList">
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep1.png')">
        <h3>新媒体全文写作</h3>
        <p>根据描述自动生成新媒体文章</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep2.png')">
        <h3>旅游攻略</h3>
        <p>生成小红书风格的旅游攻略文案</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep3.png')">
        <h3>跨部门合作决策</h3>
        <p>写一份跨部门合作决策,确保合作的顺利进行</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep4.png')">
        <h3>竞品分析</h3>
        <p>分析相关竞品</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep5.png')">
        <h3>论文创作</h3>
        <p>根据录入内容创作论文</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep6.png')">
        <h3>成果评估决策</h3>
        <p>写一份成果评估决策,矫正不良标准</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep7.png')">
        <h3>小红书文案</h3>
        <p>输入内容帮您生成符合小红书风格的笔记</p>
      </div>
      <div className="housekeepItem">
        <img :src="require('/src/assets/images/housekeep8.png')">
        <h3>标题生成文章</h3>
        <p>输入标题自动生成文章</p>
      </div>
    </div>

  </div>
  <!--  -->
  <div className="introduce">
    <div className="introduceitems">
      <img className="introduceImg" :src="require('/src/assets/images/introduce1.jpg')">
      <div className="introduceMsg">
        <h3>AI写作，高效的创作工具</h3>
        <p>AI写作技术能够通过分析海量数据、社交媒体热点和趋势预测等信息，快速生成高质量的文章等各类文本内容，并且保证准确性、质量和效率。</p>
        <a href="https://ai.shuohangai.com/#/login" className="dwonBtn">
          <!--					<i class="icon"></i>-->
          <span>登录</span>
        </a>
      </div>
    </div>
    <div className="introduceitems">
      <div className="introduceMsg">
        <h3>智能问答，答疑解惑</h3>
        <p>提供高效、准确、便捷的智能问答服务，通过机器学习和自然语言处理技术，让计算机快速理解并回答您的问题。适用于各行业领域，如学术研究、工作学习等。</p>
        <a href="https://ai.shuohangai.com/#/login" className="dwonBtn">
          <!--					<i class="icon"></i>-->
          <span>登录</span>
        </a>
      </div>
      <img className="introduceImg" :src="require('/src/assets/images/introduce2.jpg')">
    </div>
    <div className="introduceitems">
      <img className="introduceImg" :src="require('/src/assets/images/indeximg.jpg')">
      <div className="introduceMsg">
        <h3>多种模板可选、独特风格定制</h3>
        <p>AI写作工具提供多种可选的模板，让您在快速生成文本内容的同时，可以选择不同的风格和特点，从而实现更加独特且个性化的文本创作。</p>
        <a href="https://ai.shuohangai.com/#/login" className="dwonBtn">
          <!--					<i class="icon"></i>-->
          <span>登录</span>
        </a>
      </div>
    </div>
  </div>
  <!-- 文本创作场景 -->
  <div className="textCreationScenarios">
    <h2 className="h2t">文本创作场景</h2>
    <div className="scenariosList">
      <div className="scenariosItem">
        <img :src="require('/src/assets/images/cj1.png')" mode="">
        <p>通用创作</p>
      </div>
      <div className="scenariosItem">
        <img :src="require('/src/assets/images/cj2.png')">
        <p>种草撰写</p>
      </div>
      <div className="scenariosItem">
        <img :src="require('/src/assets/images/cj3.png')">
        <p>公文创作</p>
      </div>
      <div className="scenariosItem">
        <img :src="require('/src/assets/images/cj4.png')">
        <p>视频文案</p>
      </div>
    </div>
  </div>
  <!-- 用户评价 -->
  <div className="userEvaluate">
    <h2 className="h2t">用户评价</h2>
    <div className="swiper-container pl-container">
      <div className="swiper-wrapper pl-wrapper">
        <div className="swiper-slide pl-slide">
          <div className="userBox">
            <div className="userInformation">
              <img :src="require('/src/assets/images/tx1.png')">
              <div className="userName">
                <p>徐先生</p>
                <div className="xicon"><i></i><i></i><i></i><i></i><i></i></div>
              </div>
            </div>
            <i className="line"></i>
            <p>烁航AI使用起来效果不错，只要写一些概要，就能快速生成想要的文字内容，内容流畅自然，特别适合没有头绪和灵感的时候，真的太棒了！</p>
          </div>
          <div className="userBox">
            <div className="userInformation">
              <img :src="require('/src/assets/images/tx2.png')">
              <div className="userName">
                <p>安女士</p>
                <div className="xicon"><i></i><i></i><i></i><i></i><i></i></div>
              </div>
            </div>
            <i className="line"></i>
            <p>AI写作功能真是太好用了，大大节省了我的时间，还可以提供各种灵感，安利大家下载赶紧下载体验看看！</p>
          </div>
        </div>
        <div className="swiper-slide pl-slide" style="margin-top: 50px;">
          <div className="userBox">
            <div className="userInformation">
              <img :src="require('/src/assets/images/tx3.png')">
              <div className="userName">
                <p>翟先生</p>
                <div className="xicon"><i></i><i></i><i></i><i></i><i></i></div>
              </div>
            </div>
            <i className="line"></i>
            <p>做自媒体的原因，每天都需要想视频脚本，让我头疼不已，知道朋友推荐我使用这个AI写作工具，发现里面的模板这么好用，操作还简单！</p>
          </div>
          <div className="userBox">
            <div className="userInformation">
              <img :src="require('/src/assets/images/tx4.png')">
              <div className="userName">
                <p>杨女士</p>
                <div className="xicon"><i></i><i></i><i></i><i></i><i></i></div>
              </div>
            </div>
            <i className="line"></i>
            <p>这个软件非常适合小白，软件操作简单，功能十分丰富，平常需要几个小时的文档手动录入工作，用这个软件几分钟就完成了文档电子化，十分方便。</p>
          </div>
        </div>
      </div>
    </div>
    <div className="swiper-pagination"></div>
    <!--分页器。如果放置在swiper外面，需要自定义样式。-->
  </div>

  <footer className="footer">
    <span id="company"></span><span id="copyright"></span>
    <p><span id="kfpeople"></span><span id="terms"></span><span id="privacy"></span></p>
  </footer>
</template>

<script>
export default {
  name: "HelloWorld"
}
</script>

<style scoped>
@import '/src/assets/css/base.css';
@import '/src/assets/css/index.css';
</style>
